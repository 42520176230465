<template>
    <div class="menu-wrapper">
        <el-submenu v-if="menu.list && menu.list.length > 0" :index="menu.menuId.toString()">
            <template slot="title">
                <i :class="`${menu.icon}`"></i>
                <span class="menu-title__text">{{ $t(menu.name) }}</span>
            </template>
            <sidebar-item v-for="child in menu.list" :key="child.menuId" :menu="child" :taskCount="taskCount" />
        </el-submenu>
        <template v-else>
            <div v-if="menu.menuId == 330 && taskCount.plannedCount > 0">
                <el-menu-item :index="menu.url">
                    <i :class="`${menu.icon}`"></i>
                    <span slot="title" class="menu-title__text"
                        >{{ $t(menu.name) }} <button class="reminder">{{ taskCount.plannedCount }}</button></span
                    >
                </el-menu-item>
            </div>
            <div v-else-if="menu.menuId == 331 && taskCount.unPlannedCount > 0">
                <el-menu-item :index="menu.url">
                    <i :class="`${menu.icon}`"></i>
                    <span slot="title" class="menu-title__text"
                        >{{ $t(menu.name) }} <button class="reminder">{{ taskCount.unPlannedCount }}</button></span
                    >
                </el-menu-item>
            </div>
            <div v-else-if="menu.menuId == 332 && taskCount.productionCount > 0">
                <el-menu-item :index="menu.url">
                    <i :class="`${menu.icon}`"></i>
                    <span slot="title" class="menu-title__text"
                        >{{ $t(menu.name) }} <button class="reminder">{{ taskCount.productionCount }}</button></span
                    >
                </el-menu-item>
            </div>
            <div v-else-if="menu.menuId == 188 && taskCount.workflowCount > 0">
                <el-menu-item :index="menu.url">
                    <i :class="`${menu.icon}`"></i>
                    <span slot="title" class="menu-title__text"
                        >{{ $t(menu.name) }} <button class="reminder">{{ taskCount.workflowCount }}</button></span
                    >
                </el-menu-item>
            </div>
            <div v-else-if="menu.menuId == 339 && taskCount.elecOverhaulPlanned > 0">
                <el-menu-item :index="menu.url">
                    <i :class="`${menu.icon}`"></i>
                    <span slot="title" class="menu-title__text"
                        >{{ $t(menu.name) }} <button class="reminder">{{ taskCount.elecOverhaulPlanned }}</button></span
                    >
                </el-menu-item>
            </div>
            <div v-else-if="menu.menuId == 340 && taskCount.elecOverhaulUnplanned > 0">
                <el-menu-item :index="menu.url">
                    <i :class="`${menu.icon}`"></i>
                    <span slot="title" class="menu-title__text"
                        >{{ $t(menu.name) }}
                        <button class="reminder">{{ taskCount.elecOverhaulUnplanned }}</button></span
                    >
                </el-menu-item>
            </div>
            <div v-else-if="menu.menuId == 341 && taskCount.elecProduction > 0">
                <el-menu-item :index="menu.url">
                    <i :class="`${menu.icon}`"></i>
                    <span slot="title" class="menu-title__text"
                        >{{ $t(menu.name) }} <button class="reminder">{{ taskCount.elecProduction }}</button></span
                    >
                </el-menu-item>
            </div>
            <div v-else-if="menu.menuId == 375 && taskCount.elecDispatcnFlaw > 0">
                <el-menu-item :index="menu.url">
                    <i :class="`${menu.icon}`"></i>
                    <span slot="title" class="menu-title__text"
                        >{{ $t(menu.name) }} <button class="reminder">{{ taskCount.elecDispatcnFlaw }}</button></span
                    >
                </el-menu-item>
            </div>
            <div v-else-if="menu.menuId == 376 && taskCount.elecDispatcnHidden > 0">
                <el-menu-item :index="menu.url">
                    <i :class="`${menu.icon}`"></i>
                    <span slot="title" class="menu-title__text"
                        >{{ $t(menu.name) }} <button class="reminder">{{ taskCount.elecDispatcnHidden }}</button></span
                    >
                </el-menu-item>
            </div>
            <div v-else-if="menu.menuId == 378 && taskCount.elecTaskIssue > 0">
                <el-menu-item :index="menu.url">
                    <i :class="`${menu.icon}`"></i>
                    <span slot="title" class="menu-title__text"
                        >{{ $t(menu.name) }} <button class="reminder">{{ taskCount.elecTaskIssue }}</button></span
                    >
                </el-menu-item>
            </div>
            <div v-else-if="menu.menuId == 363 && taskCount.elecWorkTicket > 0">
                <el-menu-item :index="menu.url">
                    <i :class="`${menu.icon}`"></i>
                    <span slot="title" class="menu-title__text"
                        >{{ $t(menu.name) }} <button class="reminder">{{ taskCount.elecWorkTicket }}</button></span
                    >
                </el-menu-item>
            </div>
            <div v-else-if="menu.menuId == 367 && taskCount.elecUrgentRepairs > 0">
                <el-menu-item :index="menu.url">
                    <i :class="`${menu.icon}`"></i>
                    <span slot="title" class="menu-title__text"
                        >{{ $t(menu.name) }} <button class="reminder">{{ taskCount.elecUrgentRepairs }}</button></span
                    >
                </el-menu-item>
            </div>
            <el-menu-item :index="menu.url" v-else-if="menu">
                <i :class="`${menu.icon}`"></i>
                <span class="menu-title__text">{{ $t(menu.name) }}</span>
            </el-menu-item>
        </template>
    </div>
</template>

<script>
export default {
    name: 'SidebarItem',
    props: {
        menu: {
            type: Object,
            required: true,
        },
        taskCount: {
            type: Object,
            required: false,
            default() {
                return {
                    plannedCount: null,
                    productionCount: null,
                    unPlannedCount: null,
                    workflowCount: null,
                    elecOverhaulPlanned: null,
                    elecOverhaulUnplanned: null,
                    elecDispatcnHidden: null,
                    elecDispatcnFlaw: null,
                    elecProduction: null,
                    elecTaskIssue: null,
                };
            },
        },
    },
};
</script>
<style lang="less" scoped>
@import '~@/assets/style/common.less';
.reminder {
    margin-left: 10px;
    background-color: #ff0e28;
    border: none;
    border-radius: 10px;
    color: white;
    text-align: center;
}
</style>
