<template>
    <div class="sidebar-logo-container" :class="{'collapse':collapse}">
        <transition name="sidebarLogoFade">
            <router-link key="collapse" class="sidebar-logo-link" to="/">
                <img src="~@/assets/images/logo-left.png" class="sidebar-logo__left" />
                <img src="~@/assets/images/logo-right@3x.png" class="sidebar-logo__right" />
            </router-link>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'SidebarLogo',
    props: {
        collapse: {
            type: Boolean,
            required: true
        }
    }
};
</script>

<style lang="less" scoped>
.sidebarLogoFade-enter-active {
    transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
    opacity: 0;
}

    .sidebar-logo-container {
        position: relative;
        width: 100%;
        height: 50px;
        text-align: center;
        overflow: hidden;
        & .sidebar-logo-link {
        height: 100%;
        width: 100%;
        display: flex !important;
        font-size: 0;
        .sidebar-logo__left {
            vertical-align: middle;
            height: 52px;
            // margin: auto 20px;
            // margin-left: 10px;
        }
        .sidebar-logo__right {
            vertical-align: middle;
            height: 52px;
            // margin: auto 0;
        }

        .sidebar-title {
            margin: 10px;
            color: #fff;
            font-weight: 600;
            line-height: 50px;
            font-size: 14px;
            font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
            vertical-align: middle;
        }
    }

    &.collapse {
        .sidebar-logo-link {
            .sidebar-logo__left {
                position: relative;
                left: 15%;
            }
            .sidebar-logo__right {
                display: none;
            }
        }
        .sidebar-logo {
            margin: auto;
        }
    }
}
</style>
