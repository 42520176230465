<template>
    <div class="main-index">
        <el-tabs
            class="main-index__tabs"
            @wheel.native="tabMouseWheel"
            @mousedown.native.capture="isTabMouseScrolling = false"
            @touchstart.native.capture="isTabMouseScrolling = false"
            @keydown.native.capture.stop
            v-model="currentPath"
            type="border-card"
            @tab-click="handleClickTab"
            @tab-remove="handleRemoveTab"
            ref="mainTabs"
        >
            <el-tab-pane
                v-for="(item, index) in routerViewTab"
                :key="`tab_${$t(item.title)}_${index}`"
                :name="item.path"
                :closable="index !== 0"
            >
                <span
                    @contextmenu.prevent="showContextMenu(item.name, $event)"
                    class="main-content__tab--custom-label"
                    :class="index === 0 ? 'not-close' : ''"
                    slot="label"
                    >{{ item.title }}</span
                >
            </el-tab-pane>
        </el-tabs>
        <el-card class="main-index__content" ref="mainContentWrap" :body-style="bodyStyle">
            <app-main :maxKeepAlive="maxKeepAlive" :keepAliveList="keepAliveList" :routerViewTab="routerViewTab" />
        </el-card>
        <v-contextmenu ref="tabMenu">
            <v-contextmenu-item v-for="item in contextMenus" :key="item.code" @click="contextMenuHandle(item.code)">{{
                item.name
            }}</v-contextmenu-item>
        </v-contextmenu>
    </div>
</template>
<script>
import AppMain from './AppMain';
import {mapGetters} from 'vuex';
export default {
    name: 'MainIndex',
    data() {
        return {
            mainContentWidth: 1024,
            maxKeepAlive: 13,
            currentPath: '/',
            routerViewTab: [
                {
                    name: 'MainHomeMenu',
                    componentName: 'MainHomeMenu',
                    title: '首页',
                    path: '/'
                }
            ]
        };
    },
    components: {
        AppMain
    },
    computed: {
        ...mapGetters(['userMenu', 'apps']),
        contextMenus() {
            return [
                {
                    code: 'closeLeft',
                    name: '关闭左侧'
                },
                {
                    code: 'closeRight',
                    name: '关闭右侧'
                },
                {
                    code: 'closeOther',
                    name: '关闭其他'
                },
                {
                    code: 'closeAll',
                    name: '关闭所有'
                }
            ];
        },
        bodyStyle() {
            const padding = this.$route.meta.padding;
            const style = {
                minWidth: this.mainContentWidth + 'px',
                padding: '20px',
                height: '100%',
                boxSizing: 'border-box',
                // background: '#144775',
                overflowX: 'hidden',
                overflowY: 'auto',
                display: 'flex'
            };
            if (padding === 0) {
                style.padding = 0;
                // style.background = '#144775';
            }
            return style;
        },
        keepAliveList() {
            return this.routerViewTab.map(item => item.componentName);
        }
    },
    watch: {
        $route: {
            handler(val) {
                this.$root.tabView = this.routerViewTab;
                const route = this._.find(this.routerViewTab, {
                    path: val.fullPath
                });
                if (!route) {
                    this.routerViewTab.push({
                        name: val.name,
                        title: this.getTabTitle(val),
                        path: val.fullPath,
                        componentName: this.getComponentNameFromLocation(this.$router.path)
                    });
                } else {
                    route.title = this.getTabTitle(val);
                }
                if (this.currentPath !== val.fullPath) {
                    this.currentPath = val.fullPath;
                }
            },
            immediate: true
        }
    },
    methods: {
        getTabTitle(val) {
            //路由里面配置了tabTitle
            if (val.meta && val.meta.tabTitle) {
                if (typeof val.meta.tabTitle === 'function') {
                    return val.meta.tabTitle(this.$t, val);
                }
                return val.meta.tabTitle;
            }
            //远程嵌入第三方网站需要从菜单里面取tabTitle
            if (!this.menuMap) {
                this.menuMap = new Map();
                this.generateMenuMap(this.userMenu);
            }
            return this.menuMap.get(val.fullPath) || 'iframe2';
        },
        generateMenuMap(menus) {
            if (Array.isArray(menus)) {
                menus.forEach(menu => {
                    this.menuMap.set(menu.url, menu.name);
                    Array.isArray(menu.list) && this.generateMenuMap(menu.list);
                });
            }
        },
        showContextMenu(name, e) {
            //首页tab默认不显示右键菜单
            if (name === this.routerViewTab[0].name) {
                return;
            }
            this.contextMenuText = name;
            this.$refs.tabMenu.show({
                left: e.clientX,
                top: e.clientY
            });
        },
        contextMenuHandle(type) {
            const {routerViewTab, contextMenuText} = this;
            let index = 0;
            for (let i = 0, len = routerViewTab.length; i < len; i++) {
                if (routerViewTab[i].name === contextMenuText) {
                    index = i;
                    break;
                }
            }
            if (type === 'closeLeft') {
                this.routerViewTab.splice(1, index - 1);
            }
            if (type === 'closeRight') {
                this.routerViewTab.splice(index + 1, routerViewTab.length);
            }
            if (type === 'closeAll') {
                this.routerViewTab = [routerViewTab[0]];
            }
            if (type === 'closeOther') {
                this.routerViewTab = [routerViewTab[0], routerViewTab[index]];
            }
            this.$router.push(this.routerViewTab[this.routerViewTab.length - 1].path);
        },
        handleClickTab() {
            setTimeout(() => {
                this.$router.push(this.currentPath);
            }, 0);
        },
        handleRemoveTab(path) {
            const index = this._.findIndex(this.routerViewTab, {path});
            // 移除的是当前Tab才跳转到上一个Tab
            if (this.currentPath.startsWith(path)) {
                // 跳转到上一个Tab
                this.currentTabName = this.routerViewTab[index - 1].path;
                this.$router.push(this.routerViewTab[index - 1].path);
            }
            // 移除Tab
            this.$delete(this.routerViewTab, index);
            this.$delete(this.keepAliveList, index);
        },
        getComponentNameFromLocation(location) {
            const name = this._.last(this.$router.getMatchedComponents(location)).name;
            return name;
        },
        setScrollPosition(top) {
            document.querySelector('.el-card__body').scrollTop = top;
        },
        messageListener(e) {
            if (process.env.NODE_ENV === 'production' && e.origin !== 'https://bs-elec.ynlxkj.cn') {
                return;
            }
            if (e.data && (e.data.path && e.data.path.name)) {
                this.$router.push(e.data);
            }
        }
    },
    mounted() {
        this.$root.$on('fe-framework:main-content-scroll', this.setScrollPosition);
        // 关闭Tab
        this.$root.$on('fe-framework:tab:close', this.handleRemoveTab);

        window.addEventListener('message', this.messageListener);

        // 如果用户拥有企业画像查看角色，则默认显示企业画像
        if (this.$store.state.userInfo.roleCodeList.indexOf('big-screen-ent-view') > -1) {
            this.$router.push('/bigScreen');
        }
    },
    destroyed() {
        window.removeEventListener('message', this.messageListener);
    }
};
</script>
<style lang="less" scoped>
.main-index {
    // background: #144775;
    &__tabs {
        border: none;
        /deep/ .el-tabs__content {
            display: none;
        }
        /deep/ .el-tabs__item {
            line-height: 30px;
            height: 30px;
        }
        .fullscreen & {
            display: none;
        }
    }
    &__content {
        margin: 0;
        height: calc(100% - 34px);
        overflow: auto;
        .fullscreen & {
            height: 100%;
        }
    }
}
/* fade */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.28s;
}

.fade-enter,
.fade-leave-active {
    opacity: 0;
}

/* fade-transform */
.fade-transform-leave-active,
.fade-transform-enter-active {
    transition: all 0.3s;
}

.fade-transform-enter {
    opacity: 0;
    transform: translateX(-30px);
}

.fade-transform-leave-to {
    opacity: 0;
    transform: translateX(30px);
}

.v-contextmenu {
    // background-color: #17698e;
    // border: 1px solid #1099b2;
    border-radius: 4px;
}

.v-contextmenu .v-contextmenu-item.v-contextmenu-item--hover {
    color: #fff;
}

.v-contextmenu .v-contextmenu-item {
    padding: 5px 14px;
    line-height: 1;
    // color: #02f5f7;
}
</style>
