<template>
    <g-layout></g-layout>
</template>

<script>
import {mapMutations} from 'vuex';
import GLayout from '@/components/layout';
import {initSocket} from '@/utils/socket';
export default {
    name: 'MainIndex',
    components: {
        GLayout
    },
    methods: {
        ...mapMutations(['setSocketQueue']),
        initSocket() {
            initSocket().then(connection => {
                connection.on('ReceiveMessage', message => {
                    if (message) {
                        switch (message.type) {
                            case 'schedule':
                                this.setSocketQueue(message.data);
                                break;
                            case 'all':
                                this.$root.$emit('reciveMessage', message.data);
                                break;
                            default:
                                console.log('未识别消息', message);
                        }
                    }
                });
            });
        },
    },
    created() {
        // todo 连接websocket
        //this.initSocket();
    }
};
</script>

