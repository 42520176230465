<template>
    <div class="app-main">
        <keep-alive :include="keepAliveList" :max="maxKeepAlive">
        <router-view/>
        </keep-alive>
    </div>
</template>
<script>
import {mapGetters} from 'vuex';
export default {
    name: 'AppMain',
    data() {
        return {

        };
    },
    props: {
        keepAliveList: {
            type: Array,
        },
        routerViewTab: {
            type: Array,
        },
        maxKeepAlive: {
            type: Number,
            default: 10
        }
    },
    components: {
    },
    computed: {
        ...mapGetters(['cachedViews', 'apps', 'visitedViews']),
    },
    methods: {
        //根据path获取应用信息
        getAppInfoByPath(path) {
            const {apps} = this;
            for (let i = 0, len = apps.length; i < len; i++) {
                if (path.startsWith(`/${apps[i].code}`)) {
                    return apps[i];
                }
            }
            return false;
        },
    },
};
</script>

<style scoped lang="less">
    .app-main{
        border-radius: 3px;
        box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
        overflow: hidden auto;
        flex-grow: 1;
        .fullscreen & {
            height: 100%;
            padding: 0;
        }
    }
</style>

