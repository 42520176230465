<template>
    <div :class="classObj" class="app-wrap ui-layout-flex ui-height-100 ui-layout-flex--column">
        <nav-bar class="nav-bar-container"></nav-bar>
        <div class="ui-layout-flex content-container">
            <div class="sidebar-container">
                <side-bar></side-bar>
                <div class="verticality-collapse-trigger" @click="toggleSideBar">
                    <i :class="sidebar.opened ? 'el-icon-arrow-left' : 'el-icon-arrow-right'"></i>
                </div>
            </div>
            <div class="main-container ui-layout-flex ui-layout-flex--column ui-layout-flex--1 ui-overflow--auto">
                <main-index class="main-content ui-layout-flex--1"></main-index>
                <!-- <footer-bar></footer-bar> -->
            </div>
        </div>
    </div>
</template>

<script>
import NavBar from './NavBar';
import SideBar from './SideBar/';
import MainIndex from './MainIndex';
// import FooterBar from './FooterBar';
import resizeHandler from './resizeHandler';
import {mapGetters} from 'vuex';
export default {
    name: 'Layout',
    mixins: [resizeHandler],
    components: {
        NavBar,
        SideBar,
        // FooterBar,
        MainIndex,
    },
    methods: {
        handleClickOutside() {
            this.$store.dispatch('app/closeSideBar', {
                withoutAnimation: false,
            });
        },
        toggleSideBar() {
            this.$store.dispatch('app/toggleSideBar');
            //触发onresize事件，exSearchForm组件重新计算
            if (document.createEvent) {
                const event = document.createEvent('HTMLEvents');
                event.initEvent('resize', true, true);
                window.dispatchEvent(event);
            } else if (document.createEventObject) {
                window.fireEvent('onresize');
            }
        },
    },
    computed: {
        ...mapGetters(['sidebar']),
        sidebar() {
            return this.$store.state.app.sidebar;
        },
        classObj() {
            return {
                hideSidebar: !this.sidebar.opened,
                openSidebar: this.sidebar.opened,
                withoutAnimation: this.sidebar.withoutAnimation,
                mobile: this.device === 'mobile',
                fullscreen: this.$store.state.app.isFullScreen,
            };
        },
        device() {
            return this.$store.state.app.device;
        },
    },
};
</script>

<style lang="less" >
@import '~@/assets/style/variables.less';
.sidebar-container {
    transition: width 0.28s;
    width: @sideBarWidth !important;
    position: relative;
    font-size: 0px;
    // top: 50px;
    // bottom: 0;
    // left: 0;
    // z-index: 1001;
    // overflow: hidden;
    .hideSidebar & {
        width: 65px !important;
    }
    .fullscreen & {
        display: none;
    }
}
.main-container {
    height: 100%;
    transition: margin-left 0.28s;
    // margin-left: 209px;
    .hideSidebar & {
        // margin-left: 64px;
    }

    .fullscreen & {
        margin-left: 0px;
        .nav-bar-container {
            display: none;
        }
    }
}
.el-menu--collapse {
    .el-icon-arrow-right,
    .menu-title__text {
        display: none;
    }
}
.content-container {
    height: calc(100% - 50px);
}
</style>


