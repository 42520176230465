<template>
  <el-dialog
    title="修改密码"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="30%"
    :append-to-body="true">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-position="top">
      <el-form-item label="手机号">
        <span>{{ userInfo.username }}</span>
      </el-form-item>
      <el-form-item label="原密码" prop="password">
        <el-input type="password" v-model="dataForm.password"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newPassword">
        <el-input type="password" v-model="dataForm.newPassword"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="confirmPassword">
        <el-input type="password" v-model="dataForm.confirmPassword"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {mapGetters} from 'vuex';
export default {
    data () {
        let validateConfirmPassword = (rule, value, callback) => {
            if (this.dataForm.newPassword !== value) {
                callback(new Error('确认密码与新密码不一致'));
            } else {
                callback();
            }
        };
        return {
            visible: false,
            dataForm: {
                password: '',
                newPassword: '',
                confirmPassword: ''
            },
            dataRule: {
                password: [
                    {required: true, message: '原密码不能为空', trigger: 'blur'}
                ],
                newPassword: [
                    {required: true, message: '新密码不能为空', trigger: 'blur'}
                ],
                confirmPassword: [
                    {required: true, message: '确认密码不能为空', trigger: 'blur'},
                    {validator: validateConfirmPassword, trigger: 'blur'}
                ]
            }
        };
    },
    computed: {
        ...mapGetters(['userInfo'])
    },
    methods: {
        // 初始化
        init () {
            this.visible = true;
            this.$nextTick(() => {
                this.$refs.dataForm.resetFields();
            });
        },
        // 表单提交
        dataFormSubmit () {
            this.$refs.dataForm.validate((valid) => {
                if (valid) {
                    this.$client.doUpdatePassword({
                        password: this.dataForm.password,
                        newPassword: this.dataForm.newPassword
                    }).then((data) => {
                        if (data && data.code === 0) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1500,
                                onClose: () => {
                                    this.visible = false;
                                    this.$nextTick(() => {
                                        sessionStorage.removeItem('token');
                                        this.$router.push('/login');
                                    });
                                }
                            });
                        } else {
                            this.$message.error(data.msg);
                        }
                    });
                }
            });
        }
    }
};
</script>

