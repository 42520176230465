<template>
    <div class="navbar">
        <!-- <hamburger :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" /> -->
        <div class="navbar__title ui-color--white ui-font--bold">
            <img src="~@/assets/images/logo-left.png" class="sidebar-logo__left" />
            滇中新区配售电一体化管理平台
        </div>
        <div class="right-menu">
            <div class="user-info" v-show="device === 'desktop'">
                <el-dropdown :show-timeout="0" placement="bottom">
                    <span class="ui-link ui-font--mini ui-link-man">{{ userInfo.realName }}</span>
                    <el-dropdown-menu slot="dropdown">
                        <!-- <el-dropdown-item>
                            <router-link to="/PersonalCenter/PersonalCenter" class="ui-text-decoration--none">
                                个人信息
                            </router-link>
                        </el-dropdown-item> -->
                        <el-dropdown-item @click.native="updatePasswordHandle()">修改密码</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <div class="user-action">
                    <el-popover
                        placement="bottom"
                        :title="'消息列表'"
                        width="400"
                        trigger="manual"
                        :style="{position: 'relative'}"
                        v-model="visible"
                    >
                        <div
                            slot="reference"
                            @click="toggleMessageList"
                            class="message"
                            :class="{'message--new': messageList.length > 0}"
                        >
                            <div class="message--new__count" v-show="messageList.length > 0">
                                {{ messageCount | converMessageCount }}
                            </div>
                            <i class="el-icon-message message--new__icon"></i>
                            <div class="ui-link">{{ '消息' }}</div>
                        </div>
                        <div class="ui-align--center">
                            <div class="message-list" v-if="messageList.length > 0" v-infinite-scroll="load">
                                <el-card
                                    shadow="hover"
                                    :body-style="{padding: '5px'}"
                                    v-for="(message, index) in messageList"
                                    :key="index"

                                    class="message-list__item">
                                    <div slot="header" class="ui-layout-flex">
                                        <div class="header__title ui-flex--1 ui-text-overflow--ellipsis ui-font--bold">
                                            <a class="ui-link header__action" @click="openInfo(message)">{{ message.content }}</a>
                                        </div>
                                        <a class="ui-link header__action" :style="message.isRead? 'color: #545454' : 'color: #19FFD3'">[{{message.isRead ? '已读':'未读'}}]</a>
                                        <!--<a class="ui-link header__action" @click="setMessageRead(message)">[{{ '设为已读' }}]</a>-->

                                    </div>
                                    <div v-html="message.content"></div>
                                    <!--<div class="ui-align--right ui-font--mini" :style="{color: 'red'}">
                                        {{ message.content }}
                                    </div>-->
                                </el-card>
                            </div>
                            <div v-else class="ui-layout-flex" :style="{height: '200px'}">
                                <span :style="{margin: 'auto', color: '#909399'}">{{ '暂无数据' }}</span>
                            </div>
                            <div class="popover-title__action">
                                <i class="el-icon-close ui-link" @click="toggleMessageList"></i>
                            </div>
                            <p v-if="loading">加载中...</p>
                            <p v-if="noMore">没有更多了</p>
                        </div>
                        <div style="text-align: center">
                        <el-button type="primary" v-show="!allMessageRead" @click="setAllMessageRead">{{
                            '全部已读'
                            }}</el-button>
                        </div>
                    </el-popover>
                    <div @click="doUILogout" class="logout">
                        <i class="el-icon-back message--new__icon message--new__icon--fix"></i>
                        <div class="ui-link">{{ '退出' }}</div>
                    </div>
                </div>
            </div>
            <el-dropdown size="medium" trigger="click" @command="dropdownClickHandle" v-show="device !== 'desktop'">
                <div class="user-info">
                    <!-- <img :src="avatarUrl" class="user-info__avatar" /> -->
                    <router-link to="/PersonalCenter/PersonalCenter" class="ui-text-decoration--none">
                        <span class="ui-font--medium user-info__username ui-link">{{ userInfo.username }}</span>
                    </router-link>
                    <i class="el-icon-arrow-down el-icon--right"></i>
                </div>
                <el-dropdown-menu slot="dropdown">
                    <!-- <el-dropdown-item command="language">{{language | convertLanguageLabel}}</el-dropdown-item> -->
                    <el-dropdown-item command="logout">{{ '退出' }}</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <!-- 弹窗, 修改密码 -->
        <update-password v-if="updatePassowrdVisible" ref="updatePassowrd"></update-password>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import UpdatePassword from './UpdatePassword';
import * as signalR from '@microsoft/signalr';

import Cookies from 'js-cookie';

export default {
    components: {
        UpdatePassword,
    },
    data() {
        return {
            avatarUrl: '',
            visible: false,
            updatePassowrdVisible: false,
            messageList: [],
            messageCount: 0,
            SSOUrl: '',
            ReportUrl: '',
            // 多语言
            i18nValues: [
                {label: '简体中文', menuLable: '简', value: 'zh-CN'},
                {label: 'English', menuLable: 'EN', value: 'en-US'},
            ],
            pageData: {
                PageNumber: 1,
                PageSize: 10,
                ToSystem: 'elec',
                ToUser: this.$store.state.userInfo.userId,
                Type: 'web',
            },
            loading: false,
            count: 0,
        };
    },
    computed: {
        noMore() {
            return this.count === this.messageList.length;
        },
        ...mapGetters(['sidebar', 'avatar', 'userInfo', 'language']),
        i18nValue: {
            get() {
                return this.language;
            },
            set(val) {
                this.$i18n.locale = val;
                Cookies.set('language', val);
                this.$store.commit('app/SET_LANGUAGE', val);
            },
        },
        allMessageRead() {
            return this.messageList.length === 0;
        },
        device() {
            return this.$store.state.app.device;
        },
        userPicUrl() {
            return localStorage.getItem('userPicUrl') || '~@/assets/images/head_icon_accout_nor@2x.png';
        },
    },
    filters: {
        converMessageCount(val) {
            if (val > 99) {
                return '99+';
            }
            return val;
        },
        convertLanguageLabel(val) {
            if (val === 'zh-CN') {
                return 'English';
            }
            return '简体中文';
        },
    },
    methods: {
        ...mapActions(['doLogout']),
        // 修改密码
        updatePasswordHandle() {
            this.updatePassowrdVisible = true;
            this.$nextTick(() => {
                this.$refs.updatePassowrd.init();
            });
        },
        dropdownClickHandle(command) {
            const mappindg = {
                language: this.languageHandle,
                logout: this.doUILogout,
            };
            const fn = mappindg[command];
            fn && fn();
        },
        languageHandle() {
            const language = this.i18nValue === 'zh-CN' ? 'en-US' : 'zh-CN';
            // const locale = language === 'zh-CN' ? 'zh_CN' : 'en_US';
            Cookies.set('language', language);
            this.$i18n.locale = language;
            this.$store.commit('app/SET_LANGUAGE', language);
        },
        getSSOAndReportUrl() {
            this.$client.getHomeConfig().then((data) => {
                this.SSOUrl = data.sso;
                this.ReportUrl = data.viewStaffReportIm;
            });
        },
        doUILogout() {
            this.$confirm('确定进行[退出]操作?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.doLogout(this).then(() => {
                    this.$router.push('/login');
                });
            });
        },
        // toggleSideBar() {
        //     this.$store.dispatch('app/toggleSideBar');
        //     //触发onresize事件，exSearchForm组件重新计算
        //     if (document.createEvent) {
        //         const event = document.createEvent('HTMLEvents');
        //         event.initEvent('resize', true, true);
        //         window.dispatchEvent(event);
        //     } else if (document.createEventObject) {
        //         window.fireEvent('onresize');
        //     }
        // },
        getUserPicUrl() {
            this.$client.getUserPicUrl(this.userInfo.id).then((data) => {
                this.avatarUrl = data;
            });
        },
        async initWebSocket() {
            const connection = new signalR.HubConnectionBuilder()
                .withUrl('/Notification', {
                    accessTokenFactory: () => this.$store.state.jwtToken})
                .withAutomaticReconnect({
                    nextRetryDelayInMilliseconds: retryContext => {return 2000;}
                })
                .configureLogging(signalR.LogLevel.Information)
                .build();

            connection.on('ReceiveMessage', (msg) => {
                if (msg) {
                    //this.$client.saveSysMsg(msg);
                    this.$alert(msg.content, '消息详情', {
                        confirmButtonText: '确定',
                        type: 'warning',
                    });
                }
            });

            await connection.start();
            await connection.invoke('AddSystem', 'elec');
            await connection.onreconnected(connectionId => {
                if (connection.state === signalR.HubConnectionState.Connected) {
                    connection.invoke('AddSystem', 'elec');
                }
            });
        },
        openInfo(item) {
            this.$alert(item.content, '消息详情', {
                confirmButtonText: '确定',
                type: 'warning',
            });
            this.setMessageRead(item);
        },
        getMessageList() {
            this.$client.getMessageList({...this.pageData}).then(data => {
                let messageCount = 0;
                let messageList = data.data.items;
                this.count = data.data.totalItemCount;
                this.$client.getReadMessageList().then(data => {
                    let readMessgeList = data.data;
                    messageList.forEach(item => {
                        item.isRead = false;
                        readMessgeList.forEach(temp => {
                            if (item.id === temp.msgId) {
                                item.isRead = true;
                            }
                        });
                    });
                    this.messageList = this.messageList.concat(messageList);
                    this.messageList.forEach(item => {
                        if (!item.isRead) {
                            messageCount++;
                        }
                    });
                    this.messageCount = messageCount;
                });
            });
        },
        load() {
            if (!this.noMore) {
                this.loading = true;
                setTimeout(() => {
                    this.pageData.PageNumber++;
                    this.getMessageList();
                    this.loading = false;
                }, 1000);
            }
        },

        setMessageRead(message) {
            let messageList = [];
            if (!messageList.isRead) {
                let msg = {
                    msgId: message.id,
                    batchNo: message.batchNo,
                    classify: message.classify,
                    content: message.content,
                    title: message.title,
                };
                messageList.push(msg);
            }
            this.$client.isRead(messageList);
        },
        setAllMessageRead() {
            this.$confirm('确定进行[全部已读]操作?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                let messageList = [];
                this.messageList.forEach(message => {
                    if (!message.isRead) {
                        let msg = {
                            msgId: message.id,
                            batchNo: message.batchNo,
                            classify: message.classify,
                            content: message.content,
                            title: message.title,
                        };
                        messageList.push(msg);
                    }
                });
                this.$client.isRead(messageList);
            });
        },
        toggleMessageList() {
            this.visible = !this.visible;
        },
    },
    created() {
        this.getMessageList();
        //this.getUserPicUrl();
        //this.getSSOAndReportUrl();
        this.$root.$on('reciveMessage', (data) => {
            this.messageList.unshift({
                ...data,
            });
            this.visible = true;
        });
        this.initWebSocket();
    },
};
</script>

<style lang="less" scoped>
.navbar {
    display: flex;
    // height: 45px;
    height: 50px;
    overflow: hidden;
    position: relative;
    // background: #223554;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
    &__title {
        display: flex;
        align-items: center;
        margin: auto 15px;
        font-size: 18px;
        word-spacing: 3px;
    }
    .right-menu {
        margin-left: auto;
        height: 100%;
        margin-right: 10px;
        cursor: pointer;
        display: flex;
        .el-dropdown {
            display: flex;
        }
        .ui-link {
        }
        .user-info {
            display: flex;
            align-items: center;
            margin-right: 10px;
            .ui-link-man {
                margin-right: 10px;
            }
            &__avatar {
                height: 40px;
                width: auto;
                border-radius: 50%;
                margin-right: 10px;
            }
            &__username {
                font-weight: 600;
                font-size: 12px;
                margin-right: 15px;
            }
        }
        .user-action {
            display: flex;
            &--language,
            &--report {
                margin: auto 10px;
            }
        }
        .sso,
        .logout,
        .message {
            font-size: 12px;
            text-align: center;
            margin: auto 10px;
            position: relative;
            & > img {
                height: 18px;
            }
        }
        .message--new {
            &__count {
                color: white;
                min-width: 14px;
                height: 14px;
                display: inline-block;
                padding: 1px;
                line-height: 1;
                background: red;
                border-radius: 50%;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 99;
                animation: msg steps(6, start) 2000ms infinite normal backwards;
                transform: translateX(50%);
            }
            &__icon {
                font-size: 16px;
                margin-top: 5px;
            }
            &__icon--fix {
                transform: rotate(180deg);
            }
        }
    }
}
/deep/ .el-dropdown-menu__item {
    min-width: 195px;
}
.message-list {
    max-height: calc(100vh - 200px);
    overflow: auto;
    &__item {
        margin-bottom: 5px;
        .header__title {
            white-space: nowrap;
            margin-right: auto;
        }
        .header__action {
            flex-shrink: 0;
        }
    }
}
.popover-title__action {
    position: absolute;
    top: 10px;
    right: 10px;
}
@keyframes msg {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
</style>
