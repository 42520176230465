<template>
    <div class="sidebar-wrap ui-layout-flex ui-layout-flex--column ui-height-100">
        <logo v-if="showLogo" :collapse="isCollapse" />
        <div class="menu-wrapper ui-layout-flex--1 ui-overflow--auto">
            <el-menu
                class="menu-wrap"
                :collapse="isCollapse"
                :unique-opened="true"
                :collapse-transition="false"
                mode="vertical"
                :default-active="activeMenu"
                :text-color="theme.color"
                :active-text-color="theme.activeColor"
                @select="menuSelect"
            >
                <sidebar-item v-for="menu in userMenu" :key="menu.menuId" :menu="menu" :taskCount="taskCount" />
            </el-menu>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import SidebarItem from './SidebarItem';
import Logo from './Logo';
export default {
    name: 'SideBar',
    data() {
        return {
            maxTabViewCount: 10,
            showLogo: false,
            activeMenu: '',
            theme: {},
        };
    },
    watch: {
        $route: {
            handler(val) {
                this.activeMenu = val.path;
            },
            immediate: true,
        },
    },
    components: {SidebarItem, Logo},
    computed: {
        ...mapGetters(['userMenu', 'sidebar', 'taskCount']),
        isCollapse() {
            return !this.sidebar.opened;
        },
    },
    methods: {
        ...mapActions(['getTaskCount']),
        getUserInfo() {
            this.$store.dispatch('user/getUserInfo');
        },
        getUserMenu() {
            this.$store.dispatch('user/getUserMenu');
        },
        menuSelect(path) {
            const tabView = this.$root.tabView;
            const isOpen = tabView.filter((item) => item.path === path).length > 0;
            if (!isOpen && tabView.length > this.maxTabViewCount) {
                this.$message({
                    type: 'warning',
                    message: '打开页面过多，请先尝试关闭一些页面，再打开新页面。',
                });
                return;
            }
            this.$router.push({path});
        },
    },
    mounted() {
        if (this.timer) {
            clearInterval(this.timer);
        } else {
            this.timer = setInterval(() => {
                this.getTaskCount();
            }, 60000);
        }
        this.getTaskCount();
    },
    beforeDestroy() {
        this.timer && clearInterval(this.timer);
    },
};
</script>
<style lang="less" scoped>
@import '~@/assets/style/variables.less';
.menu-wrap {
    min-height: 100%;
    // background: #122c4d;
}
/deep/ .el-menu-item.is-active {
    position: relative;
    &:after {
        content: '';
        height: 100%;
        width: 4px;
        background-color: @bg-btn-light;
        position: absolute;
        top: 0;
        right: 0;
    }
}
</style>
