import {HubConnectionBuilder, LogLevel} from '@aspnet/signalr';
import store from '@/vuex';
const url = '/Notification/';

const token = store.state.token;
const timer = 100;
let count = 0;
const connection = new HubConnectionBuilder()
    .withUrl(url, {accessTokenFactory: () => token})
    .configureLogging(LogLevel.Debug)
    .build();
export const initSocket = async () => {
    try {
        console.log('开始进行ws连接');
        await connection.start();
        console.log('ws连接成功');
        connection.onclose(async () => {
            console.log('ws 连接关闭');
            setTimeout(async () => {
                await initSocket();
            }, timer);
        });
        return Promise.resolve(connection);
    } catch (err) {
        console.error('ws err:' + JSON.stringify(err));
        if (err.statusCode === 0) {
            console.log('ws 连接失败');
            setTimeout(async () => {
                console.log(`ws 第${count++}次重试`);
                await initSocket();
            }, timer);
        }
    }
};

